'use client';
import React, { useState } from "react";
import { ethers } from 'ethers'

import { EthereumProvider } from "@walletconnect/ethereum-provider";
import './Totemheads.css'

const projectId = 'f472cab22582003651568ab0dfe2bbcf';
const contractAddress = "0xd9d95421402de9a5ca365108439ccb900aee30f4";

const BASE_CHAIN=8453;
let mintCost = 0.0000;


const TOTEMHEADS = [
	"function mint(address sendTo, uint256 howMany) payable",
	"function mintPrice() view returns (uint256)"
];






export default function Mint() { 

  const connect = async  () => {
      // setConnected(true);
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      // await window.ethereum.enable();

      // const signer = provider.getSigner();
      // const thContract = new ethers.Contract(contractAddress, TOTEMHEADS, signer);
      // console.log(thContract);
      // mintCost = await thContract.mintPrice().then((result) => {
      //   return ethers.utils.formatEther( result ) 
      // });

      // console.log(mintCost);
//    } else {
    //   alert("No provider");
    //   return (
    //       <div><p>Please use your dAppBrowser for now :-)</p></div>
    //     )
    // }
    return (
      <div className="th-button"><a href="" target="opensea" rel="nonrefferer">TOTEMHEADS on OPENSEA</a></div>
    )
  };

  // const [connected, setConnected] = useState(false);

  
  const [mintStyle, setMintStyle] = useState("hidden");
  const changeMintStyle = () => {
    if (mintStyle !== "hidden") setMintStyle("hidden");
    else setMintStyle("visible");
  };

  async function mintNft(numberToMint) {
    // const cost = ((mintCost * numberToMint) * (10 ** 18)).toString();
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // await window.ethereum.enable();

    // const signer = provider.getSigner();
    // const from = await signer.getAddress();

    // const thContract = new ethers.Contract(contractAddress, TOTEMHEADS, signer);
    // await thContract.mint(from, numberToMint, { from: from, value: cost })
    //   .catch (err => {
    //     console.log({err});
    //     console.log('revert reason:', err.reason);
    //     alert("mint() error: " + err.reason);
    // });
  }

  // const refresh = () => {
  //   mobileProvider.disconnect();
  //   window.localStorage.clear();
  //   setConnected(false);
  // };

//  if (connected) {
    // return (
      
    //   <div>
    //     <div id="ATM" onClick={changeMintStyle}></div>
    //     <div id="mint" className={mintStyle} >
    //       <div id="mintPopup">
    //         <div className="closeMint" onClick={changeMintStyle}>X</div>
    //           <p>
    //             <div className="center">
    //               How many are you minting?<br/>
    //               <div id="mint1" className="mintNumber" onClick={() => mintNft(1)}>1</div>
    //               <div id="mint2" className="mintNumber" onClick={() => mintNft(2)}>2</div>
    //               <div id="mint3" className="mintNumber" onClick={() => mintNft(3)}>3</div>
    //               <div id="mint4" className="mintNumber" onClick={() => mintNft(4)}>4</div>
    //               <div id="mint5" className="mintNumber" onClick={() => mintNft(5)}>5</div>
    //             </div>
    //           </p>
    //         </div>
    //     </div>
    //   </div>
    //   )
    // }

    // const doIt = async () => {
    //   const mobileProvider = await EthereumProvider.init({
    //     projectId,
    //     chains: [BASE_CHAIN],
    //     showQrModal: true,
    //     qrModalOptions: {
    //       themeMode: "dark",
    //     },
    //   });
    // }

    // doIt();

    //<p id="connectWallet" className="th-button" onClick={connect}>Connect your wallet</p>
    return (
      <div>
      <div>
        <div id="ATM" onClick={changeMintStyle}></div>
        <div id="mint" className={mintStyle} >
          <div id="mintPopup">
            <div className="closeMint" onClick={changeMintStyle}>X</div>
            <div className="th-button"><a href="https://opensea.io/collection/totemheadsbase" target="opensea" rel="nonrefferer">TOTEMHEADS on OPENSEA</a></div>
            
            </div>
        </div>
      </div>
      </div>

    )
    
}
