'use client';
import React, { useState } from 'react';
import Rankings from './Rankings.js';
import Mint from './Mint.js';
import logo from './logo.svg';

const Totemheads = (config) => {

  const [jungleStyle, setJungleStyle] = useState("hidden");
  const changeJungleStyle = () => {
    if (jungleStyle !== "hidden") setJungleStyle("hidden");
    else setJungleStyle("visible");
  };
  const [giftStyle, setGiftStyle] = useState("hidden");
  const changeGiftStyle = () => {
    if (giftStyle !== "hidden") setGiftStyle("hidden");
    else {
      setJungleStyle("hidden");
      setPoleStyle("hidden");
      setGiftStyle("visible");

    }
  };
  const [poleStyle, setPoleStyle] = useState("hidden");
  const changePoleStyle = () => {
    if (poleStyle !== "hidden") setPoleStyle("hidden");
    else setPoleStyle("visible");
  };

  return (
    <div className="App">
      <div id="taonga">
        
        <div id="jungle" onClick={changeJungleStyle}><div id="junglePopup" onClick={changeJungleStyle} className={jungleStyle}>Keep your eyes peeled - The Jungle Runner game is well into development!</div></div>
        <div id="giftShop" onClick={changeGiftStyle}></div>
        <div id="giftShopPopup" className={giftStyle}>
          <Mint {...config}/> 
          <Rankings />
          <div id="giftShopPopupDetail" onClick={changeGiftStyle} >When the Gift Shop fully opens, you can swap family members, and check out the leaderboards!<br/><br/>For now, get some on secondary market!<br/><br/><img src="arrow.png" alt="Left Arrow" /></div>
        </div>
        
        <div id="totempole" onClick={changePoleStyle}><div id="totempolePopup" onClick={changePoleStyle} className={poleStyle}>Collect family members to build your totempole!<br/><br/>TOTEMHEADS Escrow swaps will be available in the Gift Shop.</div></div>
        <img id="logo" src={logo} className="App-logo" alt="logo"/>
      </div>
      <div class="flexGrid">
        <div className="dextools"><p><a href="https://www.dextools.io/app/en/base/pair-explorer/0x8624320527a54e14add27a8f62b787a57b8c2e67?t=1711009131652" target="_dextools" rel="noreferrer"><img src="/DEXTools_white.png" alt="Dextools"></img></a></p></div>
        <div className="baseLink"><p><a href="https://superbridge.app/base" target="_base" rel="nonreffer"><img src="/base.png" alt="BASE Chain"></img>Bridge to #BASE</a></p></div>
        <p>Opensea Collection: <a href="https://opensea.io/collection/totemheadsbase" target="_base" rel="noreferrer">TOTEMHEADS on BASE</a></p>
        <p>TOTEM on BASE Contract: <a href="https://basescan.org/token/0x4e0bd8af4e62841a602a78ff178bf7a8610f81d2" target="_base" rel="noreferrer">0x4e0bd8af4e62841a602a78ff178bf7a8610f81d2</a></p>
        <p>X (Twitter): <a href="https://twitter.com/totemheads" target="_x" rel="noreferrer">@totemheads</a></p>
        <p>Telegram: <a href="https://t.me/TOTEMHEADS" target="_tg" rel="noreferrer">TOTEMHEADS</a></p>
        


      </div>
    </div>
  );
  
}

export default Totemheads;

